import React, { useState } from "react";
import { Box, Button, Typography, TextField, Stack } from "@mui/material";

import sexyStokjes from "./sexyStokjes.json";
import samenStokjes from "./samenStokjes.json";
import bierStokjes from "./bierStokjes.json";

export default function App() {
  const [stokkieNummer, setStokkieNummer] = useState();
  const [verassing, setVerassing] = useState();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      {verassing && <Typography>{verassing}</Typography>}
      {!verassing && (
        <Box textAlign="center">
          <TextField label="Stokkie nummer" onChange={(e) => setStokkieNummer(e.target.value)} />

          <Stack spacing={2} direction="row" mt={3}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#52b788" }}
              disabled={
                0 >= stokkieNummer ||
                stokkieNummer > samenStokjes.length ||
                !stokkieNummer ||
                samenStokjes[stokkieNummer - 1] === ""
              }
              onClick={() => {
                setVerassing(samenStokjes[stokkieNummer - 1]);

                samenStokjes[stokkieNummer - 1] = "";
              }}
            >
              Groen
            </Button>

            <Button
              variant="contained"
              sx={{ backgroundColor: "#ff5d8f" }}
              disabled={
                0 >= stokkieNummer ||
                stokkieNummer > sexyStokjes.length ||
                !stokkieNummer ||
                sexyStokjes[stokkieNummer - 1] === ""
              }
              onClick={() => {
                setVerassing(sexyStokjes[stokkieNummer - 1]);
                sexyStokjes[stokkieNummer - 1] = "";
              }}
            >
              Roze
            </Button>

            <Button
              variant="contained"
              sx={{ backgroundColor: "#fb8500" }}
              disabled={
                0 >= stokkieNummer ||
                stokkieNummer > bierStokjes.length ||
                !stokkieNummer ||
                bierStokjes[stokkieNummer - 1] === ""
              }
              onClick={() => {
                setVerassing(bierStokjes[stokkieNummer - 1]);
                bierStokjes[stokkieNummer - 1] = "";
              }}
            >
              Oranje
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
